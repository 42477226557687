import { RootState } from './state';
import { V1Subscription } from '@wix/ambassador-subscriptions-api/http';
import { Subscription } from '@wix/ambassador-billing-v1-subscription/types';

export const getCancelConfirmModal = (state: RootState) => state.cancelConfirmModal;
export const getCancelConfirmModalSubscriptionId = (state: RootState) => getCancelConfirmModal(state).subscriptionId;
export const isCancelConfirmModalOpen = (state: RootState) => getCancelConfirmModal(state).isOpen;
export const getCancelConfirmModalSubscription = (state: RootState) =>
  getSubscriptionById(state, getCancelConfirmModalSubscriptionId(state));
export const isUpmModalOpen = (state: RootState) => state.upmModal.isOpen;
export const getUpmModalSubscription = (state: RootState):Subscription => getSubscriptionById(state, state.upmModal.subscriptionId);
export const getSubscriptions = (state: RootState) => state.subscriptions;
export const getSubscriptionById = (state: RootState, id: string | null) =>
  (getSubscriptions(state).entities as any[]).find(
    (subscription: V1Subscription | Subscription) => subscription.id === id,
  );
export const isLoading = (state: RootState) => state.subscriptions.loading === 'pending';
export const getDetails = (state: RootState, subscriptionId: string) => state.details.entities[subscriptionId];
export const isDetailsOpen = (state: RootState, subscriptionId: string) => state.accordion.indexOf(subscriptionId) > -1;
export const isDetailsLoading = (state: RootState, subscriptionId: string) =>
  state.details.loading.indexOf(subscriptionId) > -1;
export const getLanguage = (state: RootState) => state.language;
export const getRegionalSettings = (state: RootState) => state.regionalSettings;
